<template>
  <div>
    <base-content-management 
        title="Slider" 
        contentType="slider" 
        :enableAdd="true" 
        :enableDelete="true" 
        :useCover="true"
        :useContentCta="true"
    >
    </base-content-management>
  </div>
</template>

<script>
import BaseContentManagement from '../content/BaseContentManagement.vue'

export default {
  title () {
    return `Slider Management`
  },
  components: {
    BaseContentManagement,
  },
}
</script>

<style>

</style>
